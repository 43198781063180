@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Tus otros estilos globales aquí */
@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

body {
  @apply bg-gray-50 text-gray-900 antialiased;
}

.gradient-text {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-primary-600 to-blue-500;
}